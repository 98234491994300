import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormik } from 'formik';
import { DefaultType } from 'types/default';
import * as Yup from 'yup';

import Input from 'components/atoms/input';
import Typography from 'components/atoms/typography';

import useCurrentLanguage from '../../../hooks/use-current-language';

import {
  StyledButton,
  StyledCollapse,
  StyledCollapseItem,
  StyledConsent,
  StyledContent,
  StyledDescription,
  StyledForm,
  StyledFormBox,
  StyledFormItem,
  StyledLabel,
  StyledWrapper,
} from './contact-form.styles';

export type ContactFormType = {
  heading: string;
  description: string;
  sendEmail: string;
  button: {
    label: string;
  };
  company: {
    label: string;
  };
  consent: {
    text: string;
  };
  email: {
    label: string;
  };
  firstName: {
    label: string;
  };
  lastName: {
    label: string;
  };
  message: {
    label: string;
  };
  phone: {
    label: string;
  };
  topic: {
    label: string;
    options: {
      text: string;
    }[];
  };
  sendedEmail: () => void;
} & DefaultType;

const ContactForm = ({
  heading,
  description,
  button,
  company,
  consent,
  email,
  firstName,
  lastName,
  message,
  phone,
  topic,
  margin,
  sendedEmail,
  mobilePadding,
}: ContactFormType) => {
  const [selectedTopic, setSelectedTopic] = useState('');
  const [isOpenTopic, setIsOpenTopic] = useState(false);

  const currentLanguage = useCurrentLanguage();
  const textFiilFiead = currentLanguage === 'PL' ? 'Uzupełnij pole' : 'Fill in the field';
  const textEmail = currentLanguage === 'PL' ? 'Wpisz poprawny e-mail' : 'Enter a valid email';
  const textPhone =
    currentLanguage === 'PL' ? 'Wpisz poprawny numer telefonu' : 'Enter a valid phone number';

  const textInvalid = currentLanguage === 'PL' ? 'Nieprawidłowe znaki' : 'Invalid characters';
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required(textFiilFiead)
      .matches(/^[a-zA-Ząćęłńóśźż\s]*$/),
    lastName: Yup.string()
      .required(textFiilFiead)
      .matches(/^[a-zA-Ząćęłńóśźż\s]*$/),
    email: Yup.string()
      .required(textFiilFiead)
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, textEmail),
    phone: Yup.string()
      .required(textFiilFiead)
      .matches(/^[0-9\s]*$/, textPhone),
    company: Yup.string()
      .required(textFiilFiead)
      .matches(/^[a-zA-Z0-9ąćęłńóśźż\s]*$/),

    topic: Yup.string().required(textFiilFiead),
    message: Yup.string()
      .required(textFiilFiead)
      .matches(/^[a-zA-Z0-9ąćęłńóśźż\s.,!"'@#$%^&*()\-_=+?:;]*$/, textInvalid),
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      topic: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const token = await executeRecaptcha?.();

      if (!token) {
        return;
      }
      try {
        // Sending the form data to the specified endpoint
        const response = await fetch('https://hrk.forbetterfuture.pl/wp-json/wp/v2/hrk/form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, recaptchaToken: token }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars

        sendedEmail();
        resetForm();
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        // Handle the error: you might want to update the component state here
      }

      setSubmitting(false);
    },
  });

  return (
    <StyledWrapper margin={margin} mobilePadding={mobilePadding}>
      <StyledContent>
        <Typography variant="title2" html={heading} component="h2" />
        <StyledDescription variant="title3" component="h3" color="gray60" html={description} />
        <StyledForm onSubmit={formik.handleSubmit}>
          <StyledFormBox>
            <StyledFormItem>
              <Typography html={firstName.label} variant="title4" />
              <Input
                type="text"
                name="firstName"
                value={formik.values.firstName}
                handleChange={formik.handleChange}
                error={formik.errors.firstName}
              />
            </StyledFormItem>
            {/* lastName field */}
            <StyledFormItem>
              <Typography html={lastName.label} variant="title4" />
              <Input
                type="text"
                name="lastName"
                value={formik.values.lastName}
                handleChange={formik.handleChange}
                error={formik.errors.lastName}
              />
            </StyledFormItem>

            <StyledFormItem>
              <Typography html={email.label} variant="title4" />
              <Input
                type="email"
                name="email"
                value={formik.values.email}
                handleChange={formik.handleChange}
                error={formik.errors.email}
              />
            </StyledFormItem>
            <StyledFormItem>
              <Typography html={phone.label} variant="title4" />
              <Input
                type="tel"
                name="phone"
                value={formik.values.phone}
                handleChange={formik.handleChange}
                error={formik.errors.phone}
              />
            </StyledFormItem>
            <StyledFormItem>
              <Typography html={company.label} variant="title4" />
              <Input
                type="text"
                name="company"
                value={formik.values.company}
                handleChange={formik.handleChange}
                error={formik.errors.company}
              />
            </StyledFormItem>

            <StyledFormItem>
              <StyledLabel htmlFor={topic.label} onClick={() => setIsOpenTopic(!isOpenTopic)}>
                <Typography html={topic.label} variant="title4" />
                <Input
                  type="text"
                  name="topic"
                  value={`${selectedTopic}`}
                  handleChange={(e) => {
                    formik.handleChange(e);
                    setSelectedTopic(e.target.value);
                  }}
                  error={formik.errors.topic}
                />
              </StyledLabel>
              <StyledCollapse isOpen={isOpenTopic}>
                {topic.options.map((option) => (
                  <StyledCollapseItem
                    onClick={() => {
                      setSelectedTopic(option.text);
                      formik.setFieldValue('topic', option.text);
                      setIsOpenTopic(false);
                    }}
                  >
                    <Typography html={option.text} variant="title4" />
                  </StyledCollapseItem>
                ))}
              </StyledCollapse>
            </StyledFormItem>
          </StyledFormBox>

          <StyledFormItem message>
            <label htmlFor={message.label}>
              <Typography html={message.label} variant="title4" />
              <textarea
                id="message"
                name="message"
                maxLength={160}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.touched.message && formik.errors.message ? (
              <div>{formik.errors.message}</div>
            ) : null}
          </StyledFormItem>
          <StyledConsent>
            <Typography variant="body2" html={consent.text} />
          </StyledConsent>
          <StyledButton {...button} variant="primary" type="submit" />
        </StyledForm>
      </StyledContent>
    </StyledWrapper>
  );
};

export default ContactForm;
